.main-heading {
  font-family: Lustria;
  font-size: 24px;
}

.icons_main i {
  cursor: pointer;
  display: flex !important;
  gap: 3px !important;
}

.sidebarbtn a {
  cursor: pointer;
}

.error {
  color: red;
  font-size: small;
  font-weight: 400;
  margin: -15px 0px 0px 0px;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login_logo_img {
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: all 0.5s linear;
  animation: imgscale 1s forwards 0.5s;
}

/* ---- Modal css    */
.common-modal {
  background: rgb(0 0 0 / 40%);
}

.common-modal.sm-modal .modal-dialog {
  max-width: 400px !important;
}

.common-modal .modal-dialog {
  max-width: 700px;
}
.common-modal.xl-modal .modal-dialog {
  width: 1000px !important;
}

.common-body-modal {
  padding: 25px 30px;
  position: relative;
}

.modal-content {
  border: 0px;
  border-radius: 20px;
}

.common-body-modal .btn-close {
  position: absolute;
  content: "";
  top: 15px;
  right: 15px;
  font-size: 12px;
}

.common-body-modal .btn-close:focus {
  box-shadow: none;
}

h2.common-modal-title,
.common-modal-text p {
  text-align: center;
  margin-bottom: 0px;
}

.common-modal-form {
  margin: 10px 0px 25px;
}

.common-modal-form .form-group:not(:last-child) {
  margin-bottom: 20px;
}

.common-modal-form .form-group .form-control {
  font-size: 14px;
}

.common-modal-form .form-group label {
  text-align: left;
  display: block;
}

.common-modal-form .form-group p {
  text-align: left;
  display: block;
}

.common-modal-form .form-group input {
  text-align: left;
}

.common-modal-footer {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.modal-xl .modal-dialog {
  max-width: 1104px;
}

/* -----EDIT Input css ----- */

.editInput:focus {
  border: none;
  background-color: #80808038;
  padding: 12px;
}

.editInput {
  border: 0px;
  background-color: #dbd1d138 !important;
  padding: 12px;
}

/* ------modal css  end - */
/* ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f9ffff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #006972;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #006972;
} */

/* ---togal btn-- */
span.toggle-box {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.toggle-box input {
  display: none;
}

.toggle-box input + label {
  width: 32px;
  height: 18px;
  background: #ddd;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: all 0.5s ease;
}

.toggle-box input + label::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 4px;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

.toggle-box input:checked + label {
  background-color: #2b835d;
  box-shadow: 0px 2px 4px rgba(165, 163, 174, 0.3);
}

.toggle-box input:checked + label::before {
  left: calc(100% - 13px);
}

.navbar .notification {
  position: absolute;
  top: -2px;
  border: 1px solid #fff;
  right: -4px;
  font-size: 9px;
  background: #f44336;
  color: #fff;
  min-width: 20px;
  padding: 0 5px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  line-height: 19px;

  display: block;
}

tr.cursor-pointer:hover {
  background: #eeeeeea3;
}

.navbar-vertical.navbar-expand-xs {
  z-index: 1 !important;
}

.common-modal-footer button {
  width: 100px !important;
}

/* ---pagination ---- */
.pagination-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  padding-right: 50px;
}

label.row-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

label.row-label span {
  width: 120px;
  line-height: normal;
}

label.row-label select {
  width: calc(100% - 100px);
  background-color: var(--primary-clr);
  color: #8392ab;
}

label.row-label select.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
}

button.pagination-button {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  background: #fff;
  cursor: pointer;
}

button.pagination-button i {
  font-size: 16px;
}

button.removeimg {
  position: absolute;
  content: "";
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border: 0px;
  background: #000;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.8);
  cursor: pointer;
}

.drag-input-field input {
  display: none;
}

.drag-input-field input + label {
  padding: 78px 50px !important;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  cursor: pointer;
}

.drag-input-field input + label i {
  margin-right: 5px;
  font-size: 20px;
  color: #acacac;
}

@keyframes imgscale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.image-blk {
  width: 100%;
  height: 100%;
  background: #fff;
}

.image-blk img {
  width: 100%;
  height: 160px;
  object-fit: contain;
}

.content-blk {
  padding: 15px;
}

.content-blk :is(h1, .h2, h2) {
  font-size: 26px;
  margin-bottom: 8px;
  font-family: "Poppins";
}

.content-blk p {
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 26px;
  font-family: "Poppins";
}

.card-wrapper {
  padding: 25px 30px;
  background: #fff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  margin-top: 30px;
  border-radius: 20px;
}

.content-card-wrapper {
  padding: 25px 30px;
  background: #fff;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
  border-radius: 20px;
}

.flex-end-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.flex-end-btn-new {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.flex-wrap-blk-new {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: auto;
  padding-bottom: 10px;
}

.flex-end-btn button {
  padding: 8px 18px;
  border: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  background: #265459;
  border-radius: 5px;
}

.steps-heading h2 {
  font-size: 20px;
}

.steps-heading {
  margin-bottom: 15px;
}

.steps-content-blk {
  background: #e6ebec;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  position: relative;
}

.steps-card-img {
  width: 175px;
  height: 160px;
  padding: 10px;
  border-radius: 10px 0px 0px 10px;
  background: #dadce0;
}

.steps-card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 0px 0px 10px;
}

.steps-detail {
  width: 185px;
  padding: 10px;
}

.steps-detail h3 {
  font-size: 18px;
  margin-bottom: 0px;
}

button.step-edit {
  position: absolute;
  content: "";
  top: 10px;
  right: 35px;
  border: 0px;
  background: transparent;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.step-edit i {
  font-size: 14px;
  color: #000;
}

button.step-delete {
  position: absolute;
  content: "";
  top: 10px;
  right: 10px;
  border: 0px;
  background: transparent;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.step-delete i {
  font-size: 14px;
  color: #000;
}

.card.active {
  border: 1px solid var(--primary-clr);
}

.card.active .bg-gradient-primary {
  background: var(--primary-clr) !important;
}

.card.active .bg-gradient-primary i {
  font-size: 16px;
}

.upload_container {
  display: flex;
  gap: 33px;
  align-content: center;
  justify-content: center;
  align-items: center;
}

img.card-img-top {
  width: 100%;
  height: 150px;
  object-fit: cover;
}
.cost-status {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid #6c757d;
  border-width: 1px;
  border-radius: 5px;
  padding: 3px;
  min-width: 50px;
}
.cost-status p {
  font-size: 12px;
}
.review_div {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: flex-end;
}

.cardcontent p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  margin-bottom: 0px;
}

.card-logo-img {
  /* width: 110px; */
  width: 100%;
  height: 110px;
  border-radius: 10px;
  object-fit: cover;
  cursor: pointer;
}

.full-image-blk {
  width: 100%;
  height: 240px;
}

.full-image-blk img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.upload-img-blk2 {
  width: 208px;
  height: 124px;
  margin: -36px auto 40px;
  overflow: hidden;
  position: relative;
}

.feedback-text-div {
  padding: 12px 12px !important;
  background-color: #f7fbf9 !important;
  margin-bottom: 5px;
}

.feedback-inner-text {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: var(--pp-font) !important;
  color: var(--black-clr) !important;

  word-break: break-word !important;
}

.flex-wrap-outer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.flex-wrap-blk {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-wrap: nowrap;
  max-width: calc(100% - 145px);
  overflow-x: auto;
  /* padding-bottom: 10px; */
}

.flex-wrap-blk .steps-content-blk {
  width: 370px;
}

.addButton {
  height: 158px;
  width: 130px;
  border-radius: 10px;
}

.back_btn {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 12px;
  cursor: pointer;
}

/* ----404---*/
.page_not_found {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  flex-direction: column;
}

.number {
  background: #fff;
  position: relative;
  font: 900 30vmin "Consolas";
  letter-spacing: 5vmin;
  text-shadow: 2px -1px 0 #000, 4px -2px 0 #0a0a0a, 6px -3px 0 #0f0f0f,
    8px -4px 0 #141414, 10px -5px 0 #1a1a1a, 12px -6px 0 #1f1f1f,
    14px -7px 0 #242424, 16px -8px 0 #292929;
}

.number::before {
  background-color: #673ab7;
  background-image: radial-gradient(
      closest-side at 50% 50%,
      #ffc107 100%,
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(closest-side at 50% 50%, #e91e63 100%, rgba(0, 0, 0, 0));
  background-repeat: repeat-x;
  background-size: 40vmin 40vmin;
  background-position: -100vmin 20vmin, 100vmin -25vmin;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  -webkit-animation: moving 10s linear infinite both;
  animation: moving 10s linear infinite both;
  display: block;
  position: absolute;
  content: "";
}

@-webkit-keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

@keyframes moving {
  to {
    background-position: 100vmin 20vmin, -100vmin -25vmin;
  }
}

.text {
  font: 400 5vmin "Courgette";
  color: #006972;
}

.text span {
  font-size: 10vmin;
}

/* phone number input  */
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 8.5px 0px 10.5px 60px !important;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  color: #495057;
}

.react-tel-input .form-control:focus {
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: none !important;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: none !important;
  box-shadow: none !important;
}

.react-tel-input .form-control:focus {
  background-color: #fff;
  border-color: #d2d6da !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border-color: #d2d6da !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.react-tel-input .form-control:focus {
  background-color: #fff;
  border-color: none !important;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* question description css */
.correct-text {
  color: #26545a !important;
}

.correct-text-div {
  padding: 12px 12px !important;
  background-color: #f7fbf9 !important;
  border: 1px solid #26545a !important;
}

.note-text-div {
  padding: 12px 12px !important;
  background-color: #f7fbf9 !important;
}

.description-inner-text {
  font-size: 12px !important;
  font-weight: 400 !important;
  font-family: var(--pp-font) !important;
  color: var(--black-clr) !important;
  width: calc(100% - 31px) !important;
  word-break: break-word !important;
}

.incorrect-text {
  color: #ba1a1a !important;
}

.incorrect-text-div {
  padding: 12px 12px !important;
  background-color: #ffedeb !important;
  border: 1px solid #ba1a1a !important;
}

img.item-img {
  width: 120px;
  margin-right: 20px;
}

.item-content {
  width: calc(100% - 120px);
}
@media screen and (max-width: 375px) {
  .pagination-button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 576px) {
  .card-flex {
    flex-direction: column;
  }

  .card-flex .item-content {
    width: 100%;
  }

  .card-flex img.item-img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    margin: 0px 0px 10px 0px;
  }
}
.points {
  border-radius: 100%;
  top: 12px;
  position: absolute;
  right: 14px;
  background: #384565;
  color: white;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
}
.background_div {
  background-color: #e9ecef;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 5px;
  padding: 25px 0px;
  position: relative;
}
@media screen and (max-width: 380px) {
  .tableHeader {
    flex-direction: column;
  }
}

.remove_btn {
  width: 100px;
  padding: 10px;
  display: flex;
  border-radius: 5px;
  background-color: #ef9a9a;
  justify-content: center;
  align-items: center;
}

.reflation_select {
  font-size: 12px;
  font-weight: 400;
  font-family: var(--pp-font);
  color: var(--black-clr);
  word-break: break-word;
  margin-bottom: 0px;
  background-color: #ffefe2;
  border-radius: 5px;
}
.review_btn {
  width: 69px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #006972;
  color: white;
  border: 0px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.content_div p {
  font-size: 10px;
  margin: 0px;
}
.review_container {
  max-height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-right: 10px;
}
